<template>
    <v-container
        style="background-color:white">
        <ASuccessWithoutReload
            :api="this.api"
            @close="$emit('finish')"
        />
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
        <v-skeleton-loader
            v-if="api.isLoading" ref="skeleton" type="table">
        </v-skeleton-loader>
        <v-row
           v-if="!api.isLoading && data!=null">
            <v-col
                class="pa-0"
                cols="12"
                sm="12">
                <v-card
                    class="py-4">
                    <v-toolbar
                        class="secondary ma-4">
                        <v-toolbar-title
                            class="white--text text-h4">
                            Update Sales
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                        <v-row
                            class="mx-2">
                            <v-col
                            cols="12"
                            sm="12">
                                <div
                                    class="d-flex justify-start">
                                    <v-autocomplete
                                        :items="optionSalesperson"
                                        v-model="data.email"
                                        dense 
                                        outlined
                                        label="Salesperson"
                                        @input="updateSalespersonData">
                                    </v-autocomplete>
                                </div>
                                <!-- <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        v-model="data.salesperson_team"
                                        dense 
                                        outlined
                                        label="Team" disabled>
                                    </v-text-field>
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        v-model="data.salesperson_name"
                                        dense 
                                        outlined
                                        label="Name" disabled>
                                    </v-text-field>
                                </div> -->
                                <!-- <div
                                    class="d-flex justify-start">
                                    <v-combobox
                                        :items="optionSalesFunnel"
                                        v-model="data.sales_funnel"
                                        dense 
                                        outlined
                                        label="Sales Funnel">
                                    </v-combobox>
                                </div> -->
                                <div
                                    class="d-flex justify-end">
                                    <v-btn
                                        @click="closeModal"
                                        color="primary"
                                        plain>
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        @click="validateInput"
                                        color="primary">
                                        Submit
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../common/ASuccessWithoutReload.vue';
import AConfirmation from '../common/AConfirmation.vue';
export default {
    components:{
    AConfirmation,
    ASuccessWithoutReload,
},
    computed: mapState({
    //
    }),
    props:[
        'id'
    ],
    data: () => ({
        isPending:false,
        form:{
            salesperson:null,
            salesperson_team:null,
            salesperson_name:null,
            sales_funnel:null,
        },
        optionSalesperson: [],
        optionSalesFunnel: [],
        optionSalesTeam: [],
        optionSalesName: [],
        respData: null,

        data:null,

        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            },
       
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="update") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully update detail";
            }
            if(resp.class==="getCompanySales") {
                this.data = resp.data;    
            }
            if(resp.class === 'getUserMultipleRole') {
                this.optionSalesperson = resp.data.map(obj=>obj.email);
            }
            if(resp.class==='getListOfCustomizeDropdown') {
                // if(resp.type==='salesperson') {
                //     this.optionSalesperson = resp.data.map(item => item.email);
                //     // this.optionSalesTeam = resp.data.map(item => item.team);
                //     // this.optionSalesName = resp.data.map(item => item.name);
                //     this.respData = resp.data;
                // }
                if(resp.type==='sales_funnel') {
                    this.optionSalesFunnel = resp.data.map(item => item.sales_funnel);
                }
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        updateSalespersonData() {
            const selectedSalesperson = this.data.salesperson;

            if (selectedSalesperson && this.respData) {
                const salespersonObject = this.respData.find(item => item.salesperson === selectedSalesperson);

                if (salespersonObject) {
                    this.data.salesperson_team = salespersonObject.team || '';
                    this.data.salesperson_name = salespersonObject.name || '';
                } else {
                    console.error('Selected salesperson not found in the data array');
                }
            }
        },
        fetch(){
            let fetchCompaniesSalesApi = this.fetchCompaniesSales();
            this.$api.fetch(fetchCompaniesSalesApi);
            this.$api.fetch(this.fetchOptionsSalesperson());
            this.$api.fetch(this.fetchOptionsSalesFunnel());
        },
        fetchOptionsSalesperson() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/user/role/multiple";
            tempApi.params = {
                'roles':['salesperson','customer_service','vision_admin','sales_team_lead','sales_manager'],
            }
            return tempApi;
        },
        fetchOptionsSalesFunnel() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/dropdown/customize?type=sales_funnel";
            return tempApi;
        },
        fetchCompaniesSales() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/sales/"+this.id;
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let updateSalesApi = this.updateSales();
            this.$api.fetch(updateSalesApi);
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){
          
        },
        updateSales() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/sales/"+this.id;
            tempApi.params = this.data;
            return tempApi;
        },
        updateDataPaymentDate(data) {
            this.form.subscriptionPaymentDate = data;
        },
        convertTimeToDay(time) {
            return time.split(" ")[0];
        },
        closeModal() {
            this.$emit("close")
        }
    }
}
</script>