<template>
    <v-container
        style="background-color:white">
        <ASuccessWithoutReload
            :api="this.api"
            @close="$emit('finish')"
        />
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
        <v-skeleton-loader
            v-if="api.isLoading" ref="skeleton" type="table">
        </v-skeleton-loader>
        <v-row
           v-if="!api.isLoading && data!=null">
            <v-col
                class="pa-0"
                cols="12"
                sm="12">
                <v-card
                    class="py-4">
                    <v-toolbar
                        class="secondary ma-4">
                        <v-toolbar-title
                            class="white--text text-h4">
                            Update invoice record
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                    <v-row
                            class="mx-2">
                            <v-col
                                cols="12"
                                sm="12">
                                <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        dense 
                                        outlined 
                                        v-model="data.invoice_no"
                                        label="Invoice no.">
                                    </v-text-field>
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-autocomplete
                                        :items="requestInvoiceOptions"
                                        v-model="data.request_invoice"
                                        dense 
                                        outlined
                                        label="Client request invoice">
                                    </v-autocomplete>
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        v-model="data.requester_name"
                                        dense 
                                        outlined 
                                        label="Invoice to name">
                                    </v-text-field>
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        v-model="data.requester_address"
                                        dense 
                                        outlined 
                                        label="Invoice to address">
                                    </v-text-field>
                                </div>
                                <div>
                                    <ComponentDateModal
                                        label="Send date"
                                        :data="data.email_send_at"
                                        @updateDateData="(data)=>{
                                            this.data.email_send_at = data;
                                        }"/>
                                </div>
                                <div
                                    class="d-flex justify-end">
                                    <v-btn
                                        @click="closeModal"
                                        color="primary"
                                        plain>
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        @click="validateInput"
                                        color="primary">
                                        Submit
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../common/ASuccessWithoutReload.vue';
import AConfirmation from '../common/AConfirmation.vue';
import ComponentDateModal from '../date/ComponentDateModal.vue';
export default {
    components:{
    AConfirmation,
    ASuccessWithoutReload,
    ComponentDateModal
},
    computed: mapState({
    //
    }),
    props:[
        'id'
    ],
    data: () => ({
        requestInvoiceOptions:[
        "Yes","No"
        ],
        isPending:false,
        form:{
           invoice_no:null,
           request_invoice:null,
           requester_name:null,
           requester_address:null,
           email_send_at:null,
        },
        data:null,

        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            },
       
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="updateCompaniesInvoice") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully update detail";
            }
            if(resp.class==="getInvoiceById") {
                this.data = resp.data;
                this.data.email_send_at = (this.data.email_send_at!=null)?this.convertTimeToDay(this.data.email_send_at):this.data.email_send_at;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.$api.fetch(this.fetchCompanyInvoice());
        },
        fetchCompanyInvoice() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/invoice/id/"+this.id;
            return tempApi
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            this.$api.fetch(this.updateCompanyInvoice());
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){
          
        },
        updateCompanyInvoice() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/invoice/id/"+this.id;
            tempApi.params = this.data;
            return tempApi;
        },
        updateDataPaymentDate(data) {
            this.form.subscriptionPaymentDate = data;
        },
        convertTimeToDay(time) {
            return time.split(" ")[0];
        },
        closeModal() {
            this.$emit("close")
        }
    }
}
</script>