<template>
    <v-container
        style="background-color:white">
        <ASuccessWithoutReload
            :api="this.api"
            @close="$emit('finish')"
        />
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
        <v-skeleton-loader
            v-if="api.isLoading && data==null" ref="skeleton" type="table">
        </v-skeleton-loader>
        <v-row
            v-else>
            <v-col
                class="pa-0"
                cols="12"
                sm="12">
                <v-card
                    class="py-4">
                    <v-toolbar
                        class="secondary ma-4">
                        <v-toolbar-title
                            class="white--text text-h4">
                            Update Remark
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                    <v-row
                            class="mx-2">
                            <v-col
                            cols="12"
                            sm="12">
                                <div
                                    class="d-flex justify-start">
                                    <v-combobox
                                        v-model="data.title"
                                        :items="optionsTitle"
                                        item-value="title"
                                        item-text="title"
                                        label="Title"
                                        dense 
                                        outlined >

                                    </v-combobox>

                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-textarea outlined
                                    label="Remark"
                                    width="auto" auto-grow height="120" v-model="data.remark">
                                    </v-textarea>
                                </div>
                                <div
                                    class="d-flex justify-end">
                                    <v-btn
                                        @click="closeModal"
                                        color="primary"
                                        plain>
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        :loading="api.isLoading"
                                        @click="validateInput"
                                        color="primary">
                                        Submit
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../common/ASuccessWithoutReload.vue';
import AConfirmation from '../common/AConfirmation.vue';
export default {
    components:{
    AConfirmation,
    ASuccessWithoutReload,
},
    computed: mapState({
    
    }),
    props:[
        'id'
    ],
    data: () => ({
        isPending:false,
        form:{
           remark:null,
        },
        optionsTitle:null,
        optionRenewedStatus:null,
        optiosnOffer:null,
        optionsPrice:null,
        isAllLoading:true,
        optionsSalesperson:null,
        data:null,

        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            },
       
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="updateRemark") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully update remark detail";
            }
            if(resp.class==="getRemarkById") {
                this.data = resp.data;
                if(this.data.remark=='null' || this.data.remark==null) {
                    this.data.remark ='';
                }
                if(this.data.title=='null' || this.data.title==null) {
                    this.data.title = '';
                }
                
            }
            if(resp.class==="getAllRemarkTitle") {
                this.optionsTitle = resp.data;
            }
            if(resp.class==='getListOfDropdown') {
                let tempData = this.$_.clone(resp.data);
                let tempSalespereson =[];
                let tempPricing =[];
                let tempBonusOffer = [];
                let tempRenewalStatus = [];
                tempData.forEach(item => {
                    if(item.key==='sales_person') {
                        tempSalespereson.push(item.value);
                    }
                    if(item.key==='pricing') {
                        tempPricing.push(item.value);
                    }
                    if(item.key==='bonus_offer') {
                        tempBonusOffer.push(item.value);
                    }
                    if(item.key==='renewal_status') {
                        tempRenewalStatus.push(item.value);
                    }
                });
                this.optionsSalesperson = tempSalespereson;
                this.optionsPrice = tempPricing;
                this.optiosnOffer = tempBonusOffer;
                this.optionRenewedStatus = tempRenewalStatus;
                this.isAllLoading = false;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            // let fetchCompaniesSubscriptionHistoryApi = this.fetchCompaniesSubscriptionHistory();
            // this.$axios.all([
            //     this.$api.fetch(this.fetchOptions()),
            //     this.$api.fetch(fetchCompaniesSubscriptionHistoryApi),
            this.$axios.all([
                this.$api.fetch(this.fetchSpecialRemark()),
                this.$api.fetch(this.fetchTitleOptions()),
            ]);
            // ])
        },
        handleChange(value) {
            if (!this.data.remark.includes(value)) {
                this.data.remark = value;
            }
        },
        handleInput(value) {
            this.data.remark = value;
        },
        fetchSpecialRemark() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/special_remark/id/"+this.id
            return tempApi;
        },
        fetchTitleOptions() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/special_remark/title";
            return tempApi;
        },
        fetchOptions() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/dropdown?type[]=sales_person&type[]=pricing&type[]=bonus_offer&type[]=renewal_status"
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            this.$api.fetch(this.updateSpecialRemark());
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){
          
        },
        updateSpecialRemark() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/special_remark/id/"+this.data.id;
            let title = (typeof this.data.title === 'object')?this.data.title.title:this.data.title;
            tempApi.params = {
                "remark":this.data.remark,
                "title":title,
                "userId":this.$store.getters.getUserId,
            }
            return tempApi;
        },
        updateSubscriptionHistory() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/subscription_history/"+this.$router.history.current.params.id;
            tempApi.params = this.data;
            return tempApi;
        },
        updateDataPaymentDate(data) {
            this.form.subscriptionPaymentDate = data;
        },
        convertTimeToDay(time) {
            return time.split(" ")[0];
        },
        closeModal() {
            this.$emit("close")
        }
    }
}
</script>