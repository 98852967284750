<template>
    <v-container>
        <AError :api="this.api" />
        <ANotAllowed 
            :notAllowed="notAllowed" @close="closeNotAllowed"/>
        <v-skeleton-loader type="card" v-if="this.isLoadingSpecialRemark">
        </v-skeleton-loader>
        <v-dialog v-model="isAdding" height="700" width="700" @click:outside="() => {
            this.isAdding = false;
        }">
            <ComponentAddSpecialRemark @finish="fetch()" v-if="isAdding" @close="() => {
                this.isAdding = false;
            }" :companyId="this.companyId" />
        </v-dialog>
        <v-dialog v-model="isUpdating" height="700" width="700" @click:outside="() => {
            this.isUpdating = false;
            this.isUpdatingId = null;
        }">
            <ComponentUpdateSpecialRemark @finish="fetch()" v-if="isUpdating" @close="() => {
                this.isUpdating = false;
                this.isUpdatingId = null;
            }" :id="this.isUpdatingId" />
        </v-dialog>
        <v-row v-if="!this.isLoadingSpecialRemark">
            <v-col>
                <div
                    class="d-flex justify-space-between mb-5">
                    <span
                        class="text-h6">
                        Remark
                    </span>
                    <v-btn class="text-capitalize white--text rounded" color="blue lighten-1" @click="openNewModal">
                        <span class="font-weight-bold">
                            Add
                        </span>
                        <v-icon>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </div>
                <div v-if="specialRemark != null">
                    <v-carousel height="auto" width="auto" :continuous="false" hide-delimiters>
                        <v-carousel-item v-for="(item, index) in specialRemark" :key="index">
                            <div class="d-flex justify-end mb-4">
                                <v-btn class="custom-button text-capitalize" outlined @click="openUpdateModal(item.id)">
                                    <span class="font-weight-bold">
                                        Edit
                                    </span>
                                </v-btn>
                            </div>
                            <div
                                class="d-flex justify-space-between my-2">
                                <span>
                                    Title
                                </span>
                                <span>
                                    {{ (item.title!=null && item.title!='null')?item.title:'-'}}
                                </span>
                            </div>
                            <div
                                class="d-flex justify-space-between my-2">
                                <span>
                                    Written by :
                                </span>
                                <span>
                                    {{ (item.writer_id!=null && item.writer_id!='null')?item.username:item.excel_username}}
                                </span>
                            </div>
                            <div
                                class="d-flex justify-space-between my-2">
                                <span>
                                    Report date
                                </span>
                                <span>
                                    {{(item.created_at!=null)?convertTimeZone(item.created_at):'-'}}
                                </span>


                            </div>

                            <v-textarea class="rounded-lg" flat solo no-resize background-color="blue-grey lighten-5" readonly
                                v-model="item.remark">
                            </v-textarea>
                            <div class="d-flex justify-space-between mt-n4 mb-3">
                                <div class="font-weight-bold mt-2">
                                    {{ index + 1 }} / {{ specialRemark.length }}
                                </div>
                                <v-btn class="custom-button text-capitalize" outlined
                                    :to="{ name: 'PageCompaniesViewRemark', params: { id: item.company_id } }">
                                    <span class="font-weight-bold">
                                        Show All
                                    </span>
                                </v-btn>
                            </div>
                        </v-carousel-item>
                    </v-carousel>
                </div>
                <div v-else>
                    <span>No special remark found</span>
                </div>
            </v-col>
        </v-row>
        <hr style="border: 2px solid #F2F3F7;" />
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import ANotAllowed from '../../common/ANotAllowed.vue';
import ComponentAddSpecialRemark from '../../form/ComponentAddSpecialRemark .vue';
import ComponentUpdateSpecialRemark from '../../form/ComponentUpdateSpecialRemark.vue';

export default {
    components: {
    ANotAllowed,
    ComponentUpdateSpecialRemark,
    ComponentAddSpecialRemark,
},
    computed: mapState({
        //
    }),
    props: [
        'companyId'
    ],
    data: () => ({
        notAllowed: false,
        notAllowedAction: false,
        isLoadingSpecialRemark: false,
        actionDrop: false,
        role: null,
        page: 1,
        isAdding: false,
        isUpdating: false,
        isUpdatingId: null,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        allowedRoleUpdateStatus: ["developer", "vision_admin", "operation"],
        specialRemark: null,
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getCompanyRemark") {
                this.specialRemark = (resp.data.length != 0) ? resp.data : null;
                if(this.specialRemark !=null){
                    this.specialRemark = this.specialRemark.map(element=>{
                        if(element.remark=='null' || element.remark=='null') {
                            element.remark = '-'
                        }
                        return element;
    
                    });
                }
                this.isLoadingSpecialRemark = false;
                this.api.isLoading = false;
            }
        };
    },
    mounted() {
        this.fetch();
        // this.changeFlexShrink();
    },
    methods: {
        fetch() {
            // let fetchSubscriptionApi = this.fetchCompanySubscription(companyId);
            // let fetchRenewalStatusApi = this.fetchRenewalStatus(companyId);
            // let fetchLatestPaymentStatusApi = this.fetchPaymentStatus(companyId);
            // let fetchClientPICApi = this.fetchClientPIC(companyId);
            // let fetchRefundApi = this.fetchRefund();
            // let fetchAttachmentApi = this.fetchAttachment();
            let fetchSpecialRemarkApi = this.fetchSpecialRemark();
            // let fetchCompanySubscriptionHistoryApi = this.fetchCompanySubscriptionHistory(companyId);
            // let fetchCompanyRefundApi = this.fetchCompanyRefund(companyId);
            this.isAdding = false;
            this.isUpdating = false;
            this.isUpdatingId = null
            this.$axios.all([
                // this.$api.fetch(fetchSubscriptionApi),
                // this.$api.fetch(fetchClientPICApi),
                // this.$api.fetch(fetchRenewalStatusApi),
                // this.$api.fetch(fetchLatestPaymentStatusApi),
                // this.$api.fetch(fetchRefundApi),
                // this.$api.fetch(fetchAttachmentApi),
                this.$api.fetch(fetchSpecialRemarkApi),
                // this.$api.fetch(fetchCompanySubscriptionHistoryApi),
                // this.$api.fetch(fetchCompanyRefundApi),
            ]);
        },
        
        fetchSpecialRemark() {
            this.isLoadingSpecialRemark = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/special_remark/company/" + this.companyId;
            return tempApi;
        },
        convertTimeZone(time) {
            return this.$moment(time).format('LLL');
        },
        openNewModal() {
            if(this.allowedRoleUpdateStatus.some(role=>this.$store.getters.getRole.includes(role))) {
                this.isAdding = true;
                return 1;
            }

            this.notAllowed = true;
        },
        closeNotAllowed() {
            this.notAllowed = false;
        },
        openUpdateModal(id) {
            if(this.allowedRoleUpdateStatus.some(role=>this.$store.getters.getRole.includes(role))) {
                this.isUpdatingId = id;
                this.isUpdating = true;
                return 1;
            }
            this.notAllowed = true;
        },
    },
}
</script>

<style scoped>
.custom-button {
    border: 2px solid black;
    border-radius: 5px;
}
</style>