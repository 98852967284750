<template>
    <v-container
        style="background-color:white">
        <ASuccessWithoutReload
            :api="this.api"
            @close="$emit('finish')"
        />
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
        <v-skeleton-loader
            v-if="api.isLoading && data==null" ref="skeleton" type="table">
        </v-skeleton-loader>
        <v-row
            v-else>
            <v-col
                class="pa-0"
                cols="12"
                sm="12">
                <v-card
                    class="py-4">
                    <v-toolbar
                        class="secondary ma-4">
                        <v-toolbar-title
                            class="white--text text-h4">
                            Update Subscription History
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                    <v-row
                            class="mx-2">
                            <v-col
                            cols="12"
                            sm="12">
                                <div
                                    class="d-flex justify-start ">
                                    <v-autocomplete
                                        :items="optionRenewedStatus"
                                        v-model="data.status"
                                        dense 
                                        outlined
                                        :loading="isAllLoading"
                                        label="Status">
                                    </v-autocomplete>
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        v-model="data.year"
                                        dense 
                                        outlined
                                        label="Year">

                                    </v-text-field>
                                </div>
                                <div class="d-flex justify-start">
                                    <v-text-field v-model="data.subscription_year" dense outlined
                                        label="Year(s) of Subscription">
                                    </v-text-field>
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-autocomplete
                                        v-model="data.price"
                                        dense 
                                        outlined
                                        label="price ( RM )"
                                        :items="optionsPrice">
                                    </v-autocomplete>
                                </div>
                                <div
                                    class="">
                                    <ComponentDateModal
                                        label="Payment date"
                                        :data="data.payment_date"
                                        @updateDateData="(data)=>{
                                            this.data.payment_date = data;
                                        }"/>
                                </div>
                                <v-row>
                                    <v-col>
                                        <ComponentDateModal
                                            label="Start date"
                                            :data="data.subscription_start_date"
                                            @updateDateData="(data)=>{
                                                this.data.subscription_start_date = data;
                                            }"/>
                                    </v-col>
                                    <v-col>
                                        <ComponentDateModal
                                            label="End date"
                                            :data="data.subscription_end_date"
                                            @updateDateData="(data)=>{
                                                this.data.subscription_end_date = data;
                                            }"/>
                                    </v-col>
                                </v-row>
                               
                                <div
                                    class="">
                                    <ComponentDateModal
                                        label="Commision release date"
                                        :data="data.commision"
                                        @updateDateData="(data)=>{
                                            this.data.commision = data;
                                        }"/>
                                </div>
                                <div>
                                    <v-autocomplete
                                        dense 
                                        outlined
                                        item-text="salesperson"
                                        item-value="salesperson"
                                        :items="optionsSalesperson"
                                        v-model="data.pic_salesperson"
                                        label="Salesperson">

                                    </v-autocomplete>
                                </div>
                                <div>
                                    <v-autocomplete
                                    :items="optiosnOffer"
                                    v-model="data.offer"
                                    label="Offer"
                                    dense 
                                    outlined>

                                    </v-autocomplete>
                                </div>
                                <div>
                                    <v-autocomplete
                                        :items="optionsCancellationReason"
                                        v-model="data.cancellation_reason"
                                        label="Cancellation reason"
                                        dense 
                                        outlined>
                                    </v-autocomplete>
                                </div>
                                <div
                                    class="d-flex justify-end">
                                    <v-btn
                                        @click="closeModal"
                                        color="primary"
                                        plain>
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        :loading="api.isLoading"
                                        @click="validateInput"
                                        color="primary">
                                        Submit
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ComponentDateModal from '../date/ComponentDateModal.vue';
import ASuccessWithoutReload from '../common/ASuccessWithoutReload.vue';
import AConfirmation from '../common/AConfirmation.vue';
export default {
    components:{
    AConfirmation,
    ComponentDateModal,
    ASuccessWithoutReload,
},
    computed: mapState({
    
    }),
    props:[
        'id'
    ],
    data: () => ({
        isPending:false,
        form:{
            name:null,
            cs_pic:null,
            year_1_payment_date:null,
            year_1_subscription_start_date:null,
            year_1_subscription_end_date:null,
            year_1_pic_salesperson:null,
            year_2_subscription_status:null,
            year_2_payment_date:null,
            year_2_subscription_start_date:null,
            year_2_subscription_end_date:null,
            year_2_pic_salesperson:null,
            year_3_subscription_status:null,
            year_3_payment_date:null,
            year_3_subscription_start_date:null,
            year_3_subscription_end_date:null,
            year_3_pic_salesperson:null,
        },
        optionRenewedStatus:null,
        optiosnOffer:null,
        optionsPrice:null,
        isAllLoading:true,
        optionsSalesperson:null,
        data:null,

        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            },
       
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="update") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully update detail";
            }
            if(resp.class==="getSubscriptionHistory") {
                this.data = resp.data;
                this.data.subscription_start_date = (this.data.subscription_start_date!=null)?this.convertTimeToDay(this.data.subscription_start_date):this.data.subscription_start_date;
                this.data.subscription_end_date = (this.data.subscription_end_date!=null)?this.convertTimeToDay(this.data.subscription_end_date):this.data.subscription_end_date;
                this.data.payment_date = (this.data.payment_date!=null)?this.convertTimeToDay(this.data.payment_date):this.data.payment_date;
                this.data.commision = (this.data.commision!=null)?this.convertTimeToDay(this.data.commision):this.data.commision;

                
            }
            if(resp.class==='getListOfCustomizeDropdown') {
                if(resp.type==='salesperson') {
                    this.optionsSalesperson = resp.data;
                }
            }
            if(resp.class==='getListOfDropdown') {
                let tempData = this.$_.clone(resp.data);
                let tempPricing =[];
                let tempBonusOffer = [];
                let tempRenewalStatus = [];
                tempData.forEach(item => {
                  
                    if(item.key==='pricing') {
                        tempPricing.push(item.value);
                    }
                    if(item.key==='bonus_offer') {
                        tempBonusOffer.push(item.value);
                    }
                    if(item.key==='renewal_status') {
                        tempRenewalStatus.push(item.value);
                    }
                });
                this.optionsPrice = tempPricing;
                this.optiosnOffer = tempBonusOffer;
                this.optionRenewedStatus = tempRenewalStatus;
                this.isAllLoading = false;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchCompaniesSubscriptionHistoryApi = this.fetchCompaniesSubscriptionHistory();
            this.$axios.all([
                this.$api.fetch(this.fetchOptions()),
                this.$api.fetch(fetchCompaniesSubscriptionHistoryApi),
                this.$api.fetch(this.fetchOptionsSalesperson()),
            ])
        },
        fetchCompaniesSubscriptionHistory() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/subscription_history/"+this.id;
            return tempApi;
        },
        fetchOptions() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/dropdown??type[]=pricing&type[]=bonus_offer&type[]=renewal_status"
            return tempApi;
        },
        fetchOptionsSalesperson() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/dropdown/customize?type=salesperson";
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let updateSubscriptionHistoryApi = this.updateSubscriptionHistory();
            this.$api.fetch(updateSubscriptionHistoryApi);
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){
          
        },
        updateSubscriptionHistory() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/subscription_history/"+this.$router.history.current.params.id;
            tempApi.params = this.data;
            return tempApi;
        },
        updateDataPaymentDate(data) {
            this.form.subscriptionPaymentDate = data;
        },
        convertTimeToDay(time) {
            return time.split(" ")[0];
        },
        closeModal() {
            this.$emit("close")
        }
    }
}
</script>