<template>
    <v-container
        style="background-color:white">
        <ASuccessWithoutReload
            :api="this.api"
            @close="$emit('finish')"
        />
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
        <v-skeleton-loader
            v-if="api.isLoading" ref="skeleton" type="table">
        </v-skeleton-loader>
        <v-row
           v-if="!api.isLoading && data!=null">
            <v-col
                class="pa-0"
                cols="12"
                sm="12">
                <v-card
                    class="py-4">
                    <v-toolbar
                        class="secondary ma-4">
                        <v-toolbar-title
                            class="white--text text-h4">
                            Update AAE
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                        <v-row
                            class="mx-2">
                            <v-col
                            cols="12"
                            sm="12">
                            <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        v-model="data.invoice_no"
                                        dense 
                                        outlined
                                        label="AAE Invoice">
                                    </v-text-field>
                                </div>
                                <!-- <div
                                    class="d-flex justify-start">
                                    <v-autocomplete
                                        :items="optionAAEStatus"
                                        v-model="data.status"
                                        dense 
                                        outlined
                                        label="Status">
                                    </v-autocomplete>
                                </div> -->
                                <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        v-model="data.year"
                                        dense 
                                        outlined
                                        label="Year">
                                    </v-text-field>
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-text-field
                                        v-model="data.year_of_subscription"
                                        dense 
                                        outlined
                                        label="Year(s) of Subscription">
                                    </v-text-field>
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-autocomplete
                                        :items="optionAAEPrice"
                                        v-model="data.price"
                                        dense 
                                        outlined
                                        label="Price">
                                    </v-autocomplete>
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-autocomplete
                                        :items="optionAAEFunnel"
                                        v-model="data.funnel_person"
                                        dense 
                                        outlined
                                        label="Funnel Person">
                                    </v-autocomplete>
                                </div>
                                <div
                                    class="">
                                    <ComponentDateModal
                                        label="Invoice Sent Date"
                                        :data="data.invoice_sent_date"
                                        @updateDateData="(data)=>{
                                            this.data.invoice_sent_date = data;
                                        }"/>
                                </div>
                                <div
                                    class="">
                                    <ComponentDateModal
                                        label="Payment Date"
                                        :data="data.payment_date"
                                        @updateDateData="(data)=>{
                                            this.data.payment_date = data;
                                        }"/>
                                </div>
                                <div class="">
                                    <ComponentDateModal label="Subscription Start" :data="data.subscription_start"
                                        @updateDateData="handleSubscriptionStartDate" />
                                </div>
                                <!-- <div
                                    class="">
                                    <ComponentDateModal label="Subscription End" :data="data.subscription_end" disabled />
                                </div> -->
                                <div
                                    class="d-flex justify-end">
                                    <v-btn
                                        @click="closeModal"
                                        color="primary"
                                        plain>
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        @click="validateInput"
                                        color="primary">
                                        Submit
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ComponentDateModal from '../date/ComponentDateModal.vue';
import ASuccessWithoutReload from '../common/ASuccessWithoutReload.vue';
import AConfirmation from '../common/AConfirmation.vue';
export default {
    components:{
    AConfirmation,
    ComponentDateModal,
    ASuccessWithoutReload,
},
    computed: mapState({
    //
    }),
    props:[
        'id'
    ],
    data: () => ({
        isPending:false,
        form:{
            price:null,
            payment_date:null,
            subscription_start:null,
            subscription_end:null,
            funnel_person:null,
            invoice_no:null,
            invoice_sent_date:null,
            status:null,
            year:null,
            year_of_subscription:null,
        },
        optionAAEStatus:[
            "Year 1",
            "Renewal - Year 2",
            "Renewal - Year 3",
        ],
        optionAAEPrice:[],
        optionAAEFunnel:[],  
        data:null,

        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            },
       
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="update") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully update detail";
            }
            if(resp.class==="getCompanyAAE") {
                this.data = resp.data;
                this.data.payment_date = (this.data.payment_date!=null)?this.convertTimeToDay(this.data.payment_date):this.data.payment_date;
                this.data.subscription_start = (this.data.subscription_start!=null)?this.convertTimeToDay(this.data.subscription_start):this.data.subscription_start;
                this.data.subscription_end = (this.data.subscription_end!=null)?this.convertTimeToDay(this.data.subscription_end):this.data.subscription_end;
                this.data.invoice_sent_date = (this.data.invoice_sent_date!=null)?this.convertTimeToDay(this.data.invoice_sent_date):this.data.invoice_sent_date;      
            }
            if (resp.class === "getListOfDropdown") {
                this.optionAAEPrice = resp.data.map(item => item.value);
            }
            if(resp.class==='getListOfCustomizeDropdown') {
                if(resp.type==='salesperson') {
                    this.optionAAEFunnel = resp.data.map(item => item.salesperson);
                }
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        handleSubscriptionStartDate(date) {
            this.data.subscription_start = date;
            this.updateDates();
        },
        updateDates() {
            if (this.data.subscription_start) {
                const start = new Date(this.data.subscription_start);
                const end = new Date(start);
                end.setFullYear(start.getFullYear() + 1);
                this.data.subscription_end = end.toISOString().split('T')[0];
            }
        },
        fetch(){
            let fetchCompaniesAAEApi = this.fetchCompaniesAAE();
            this.$api.fetch(fetchCompaniesAAEApi);
            this.$api.fetch(this.fetchOptionsSalesperson());
            const types = ['aae_price'];
            this.$api.fetch(this.fetchDropdownOptions(types))
                .then(this.handleFetchResponses)
                .catch(error => {
                    console.error("Error in fetch method:", error);
                });
        },
        fetchDropdownOptions(types) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/developer/excel/dropdown?type[]=" + types;
            return tempApi;
        },
        fetchOptionsSalesperson() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/dropdown/customize?type=salesperson";
            return tempApi;
        },
        fetchCompaniesAAE() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/aae/"+this.id;
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let updateAAEApi = this.updateAAE();
            this.$api.fetch(updateAAEApi);
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){
          
        },
        updateAAE() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/aae/"+this.$router.history.current.params.id;
            tempApi.params = this.data;
            return tempApi;
        },
        updateDataPaymentDate(data) {
            this.form.subscriptionPaymentDate = data;
        },
        convertTimeToDay(time) {
            return time.split(" ")[0];
        },
        closeModal() {
            this.$emit("close")
        }
    }
}
</script>