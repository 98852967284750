<template>
    <v-container
        style="background-color:white">
        <ASuccessWithoutReload
            :api="this.api"
            @close="$emit('finish')"
        />
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
        <v-skeleton-loader
            v-if="api.isLoading" ref="skeleton" type="table">
        </v-skeleton-loader>
        <v-row
           v-if="!api.isLoading && data!=null">
            <v-col
                class="pa-0"
                cols="12"
                sm="12">
                <v-card
                    class="py-4">
                    <v-toolbar
                        class="secondary ma-4">
                        <v-toolbar-title
                            class="white--text text-h4">
                            Update Funnel
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                        <v-row
                            class="mx-2">
                            <v-col
                            cols="12"
                            sm="12">
                            <div class="">
                                    <ComponentDateModal label="Funnel Event Date" :data="data.funnel_event_date"
                                        @updateDateData="(data) => {
                                            this.data.funnel_event_date = data;
                                        }" />
                                </div>
                                <div class="d-flex justify-start">
                                    <v-autocomplete :items="optionLeadSource" v-model="data.lead_source" dense outlined
                                        label="Lead Source">
                                    </v-autocomplete>
                                </div>
                                <div
                                    class="d-flex justify-end">
                                    <v-btn
                                        @click="closeModal"
                                        color="primary"
                                        plain>
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        @click="validateInput"
                                        color="primary">
                                        Submit
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ComponentDateModal from '../date/ComponentDateModal.vue';
import ASuccessWithoutReload from '../common/ASuccessWithoutReload.vue';
import AConfirmation from '../common/AConfirmation.vue';
export default {
    components:{
    AConfirmation,
    ASuccessWithoutReload,
    ComponentDateModal,
},
    computed: mapState({
    //
    }),
    props:[
        'id'
    ],
    data: () => ({
        isPending:false,
        form:{
            lead_source: null,
            funnel_event_date: null,
        },
        optionLeadSource: [],
        data:null,

        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            },
       
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="update") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully update detail";
            }
            if(resp.class==="getCompanyFunnel") {
                this.data = resp.data;    
            }
            if(resp.class==='getListOfCustomizeDropdown') {
                if(resp.type==='lead') {
                    this.optionLeadSource = resp.data.map(item => item.lead);
                }
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchCompaniesFunnelApi = this.fetchCompaniesFunnel();
            this.$api.fetch(fetchCompaniesFunnelApi);
            this.$api.fetch(this.fetchOptionsLead());
        },
        fetchOptionsLead() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/dropdown/customize?type=lead";
            return tempApi;
        },
        fetchCompaniesFunnel() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/funnel/"+this.id;
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let updateFunnelApi = this.updateFunnel();
            this.$api.fetch(updateFunnelApi);
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){
          
        },
        updateFunnel() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/funnel/"+this.$router.history.current.params.id;
            tempApi.params = this.data;
            return tempApi;
        },
        updateDataPaymentDate(data) {
            this.form.subscriptionPaymentDate = data;
        },
        convertTimeToDay(time) {
            return time.split(" ")[0];
        },
        closeModal() {
            this.$emit("close")
        }
    }
}
</script>